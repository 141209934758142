import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import './DebugTrace.scss';

import DataGrid, { Sorting, Selection, HeaderFilter,
   Scrolling, SearchPanel, ColumnChooser, Export,
    Column, Toolbar, Item, LoadPanel, DataGridTypes
  , ColumnFixing, FilterRow, GroupPanel, RequiredRule,
  Editing,Grouping, RemoteOperations
} from 'devextreme-react/data-grid';
import Button from 'devextreme-react/button';
import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';
import { DynamicGet } from '../../utils/DynamicGet';
import { getApi } from '../../api/ServerCalls/Authorized/Generic/Dynamic';

const exportFormats = ['xlsx', 'pdf'];

export const DebugTrace = () => {
  const [gridDataSource, setGridDataSource] = useState(new DataSource({
    store: new ArrayStore({
      data: [],
      key: 'Id'
    }),
    paginate: true,
    pageSize: 10
  }));

  const [expanded, setExpanded] = useState(true);

  const [isLoading, setIsLoading] = useState(false);
  const [dbDynamicGet, setDbDynamicGet] = useState(new DynamicGet());

  const [filterValues, setFilterValues] = useState([]);
  const [sortValues, setSortValues] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(1);

  const dataGridRef = useRef<any>(null);

  useMemo(() => {
    dbDynamicGet.page = '58';
    dbDynamicGet.pageSize = 5;
    dbDynamicGet.pageIndex = 1;
  }, [dbDynamicGet]);

  const refresh = useCallback(async () => {
    //setIsLoading(true);
    if (dataGridRef.current) {
      dataGridRef.current.instance.beginCustomLoading("Loading...");
    }
    try {
      const result = await getApi(dbDynamicGet);
      if (result.isOk && result.data && Array.isArray(result.data)) {
        setGridDataSource(new DataSource({
          store: new ArrayStore({
            data: result.data,
            key: 'Id'
          }),
          paginate: true,
          pageSize: dbDynamicGet.pageSize
        }));
      } else {
        console.error('Failed to fetch data:', result.message || "Data received is not an array.");
      }
    } catch (error) {
      console.error('Error fetching database details:', error);
    }
    //setIsLoading(false);
    if (dataGridRef.current) {
      dataGridRef.current.instance.endCustomLoading();
    }
  }, []);

  useEffect(() => {
    refresh();
  }, [refresh]);


  const handleOptionChanged = useCallback((e:any) => {
  
    console.log(e);
    if (e.fullName === 'filterValue') {
      setFilterValues(e.value);
    } else if (e.fullName === 'sortByGroupSummaryInfo') {
      setSortValues(e.value);
    } else if (e.fullName === 'paging.pageSize') {
      setPageSize(e.value);
    } else if (e.fullName === 'paging.pageIndex') {
      setPageIndex(e.value);
    }
    refresh();
  }, [dbDynamicGet,refresh]);

const cellIdRender = (cell: DataGridTypes.ColumnCellTemplateData) => (
    <div className='Id'>
      <div>{cell.data.Id}</div>
    </div>
  );


const cellSourceRender = (cell: DataGridTypes.ColumnCellTemplateData) => (
    <div className='Source'>
      <div>{cell.data.Source}</div>
    </div>
  );


const cellGravityRender = (cell: DataGridTypes.ColumnCellTemplateData) => (
    <div className='Gravity'>
      <div>{cell.data.Gravity}</div>
    </div>
  );


const cellId_SysApiRender = (cell: DataGridTypes.ColumnCellTemplateData) => (
    <div className='Id_SysApi'>
      <div>{cell.data.Id_SysApi}</div>
    </div>
  );


const cellMessageRender = (cell: DataGridTypes.ColumnCellTemplateData) => (
    <div className='Message'>
      <div>{cell.data.Message}</div>
    </div>
  );


const cellNamespaceRender = (cell: DataGridTypes.ColumnCellTemplateData) => (
    <div className='Namespace'>
      <div>{cell.data.Namespace}</div>
    </div>
  );


const cellClientTimestampRender = (cell: DataGridTypes.ColumnCellTemplateData) => (
    <div className='ClientTimestamp'>
      <div>{cell.data.ClientTimestamp}</div>
    </div>
  );


const cellServerTimestampRender = (cell: DataGridTypes.ColumnCellTemplateData) => (
    <div className='ServerTimestamp'>
      <div>{cell.data.ServerTimestamp}</div>
    </div>
  );

return (
    //todo changename
    <div className='view crm-contact-list'>
      <div className='view-wrapper view-wrapper-contact-list list-page'>
        <DataGrid
          ref={dataGridRef}
          id="dataGrid"
          className='grid theme-dependent'
          columnAutoWidth={true}
          allowColumnReordering={true}
          showBorders={true}
          keyExpr="Id"
          noDataText=''
          focusedRowEnabled={false}
          height='100%'
          dataSource={gridDataSource}
          onOptionChanged={handleOptionChanged}

          //onRowClick={onRowClick}
          //onExporting={onExporting}
          //ref={gridRef}
        >
          <RemoteOperations
                    filtering={true}
                    paging={true}
                    sorting={true}
                    summary={true}
                    grouping={true}
                    groupPaging={true}
                />
          <Grouping autoExpandAll={expanded} />
          <ColumnFixing enabled={true}/>
          <ColumnChooser enabled={true} />
          <Sorting mode='multiple' />
          <FilterRow visible={true} />
          <SearchPanel visible={false} />
          <GroupPanel visible={true} />
          <HeaderFilter visible ={true}/>
          <Editing
                    mode="popup"
                    allowUpdating={true}
                    allowDeleting={true}
                    allowAdding={true}
                />
          <LoadPanel
          showPane={true}
          showIndicator={true}
          enabled={true} />
          {/* <SearchPanel visible placeholder='Contact Search' /> */}
          <Export enabled allowExportSelectedData formats={exportFormats} />
          <Selection
            selectAllMode='allPages'
            showCheckBoxesMode='none'
            mode='multiple'
          />
          <Scrolling mode='virtual' />
          <Toolbar>
            <Item location='before'>
              <div className='grid-header'>System databases</div>
            </Item>
            {/* <Item location='after' locateInMenu='auto'>
              <div className='separator' />
            </Item> */}
            {/* <Item name="groupPanel" /> */}
            {/* <Item location="after">
              <Button
                  text={expanded ? 'Collapse All' : 'Expand All'}
                  width={136}
                  onClick={() => setExpanded(prevExpanded => !prevExpanded)}
              />
            </Item> */}
            <Item
              location='after'
              locateInMenu='auto'
              showText='inMenu'
              widget='dxButton'
            >
              <Button
                icon='refresh'
                text='Refresh'
                stylingMode='text'
                onClick={refresh}
              />
            </Item>
            <Item location='after' locateInMenu='auto'>
              <div className='separator' />
            </Item>
            <Item name="addRowButton" showText="inMenu" />
            <Item location='after' locateInMenu='auto'>
              <div className='separator' />
            </Item>
            <Item name='exportButton' />
            <Item location='after' locateInMenu='auto'>
              <div className='separator' />
            </Item>
            <Item name='columnChooserButton' locateInMenu='auto' />
            <Item name='searchPanel' locateInMenu='auto' />
          </Toolbar>
<Column
            dataField='Id'
            caption='Id'

            fixed={undefined}
            visible={undefined}
            defaultSortOrder={undefined}
            groupIndex={undefined}

            //sortOrder='asc'
            hidingPriority={2}
            minWidth={150}
            cellRender={cellIdRender}
          />

<Column
            dataField='Source'
            caption='Source'

            fixed={undefined}
            visible={undefined}
            defaultSortOrder={undefined}
            groupIndex={undefined}

            //sortOrder='asc'
            hidingPriority={2}
            minWidth={150}
            cellRender={cellSourceRender}
          />

<Column
            dataField='Gravity'
            caption='Gravity'

            fixed={undefined}
            visible={undefined}
            defaultSortOrder={undefined}
            groupIndex={undefined}

            //sortOrder='asc'
            hidingPriority={2}
            minWidth={150}
            cellRender={cellGravityRender}
          />

<Column
            dataField='Id_SysApi'
            caption='Id_SysApi'

            fixed={undefined}
            visible={undefined}
            defaultSortOrder={undefined}
            groupIndex={undefined}

            //sortOrder='asc'
            hidingPriority={2}
            minWidth={150}
            cellRender={cellId_SysApiRender}
          />

<Column
            dataField='Message'
            caption='Message'

            fixed={undefined}
            visible={undefined}
            defaultSortOrder={undefined}
            groupIndex={undefined}

            //sortOrder='asc'
            hidingPriority={2}
            minWidth={150}
            cellRender={cellMessageRender}
          />

<Column
            dataField='Namespace'
            caption='Namespace'

            fixed={undefined}
            visible={undefined}
            defaultSortOrder={undefined}
            groupIndex={undefined}

            //sortOrder='asc'
            hidingPriority={2}
            minWidth={150}
            cellRender={cellNamespaceRender}
          />

<Column
            dataField='ClientTimestamp'
            caption='ClientTimestamp'

            fixed={undefined}
            visible={undefined}
            defaultSortOrder={undefined}
            groupIndex={undefined}

            //sortOrder='asc'
            dataType="date"
            hidingPriority={2}
            minWidth={150}
            cellRender={cellClientTimestampRender}
          />

<Column
            dataField='ServerTimestamp'
            caption='ServerTimestamp'

            fixed={undefined}
            visible={undefined}
            defaultSortOrder={undefined}
            groupIndex={undefined}

            //sortOrder='asc'
            dataType="date"
            hidingPriority={2}
            minWidth={150}
            cellRender={cellServerTimestampRender}
          />
           </DataGrid>
      </div>
    </div>
  );
};
