//#region Imports

import axios, { AxiosError } from 'axios';
import { sendPost, sendGet, sendDelete, sendPatch, sendPut } from '../../../Helpers'
import { ApiResponse } from '../../../../types/api-response';
import { DynamicGet } from '../../../../utils/DynamicGet'; 

//#endregion Imports

//#region Methods


export async function getApi(  dynamicGet: DynamicGet): Promise<ApiResponse> 
{
   try
   {
      const body = { DynamicGet: dynamicGet };
      return await sendPost('/dynamic/getApi',  body);
   }
   catch { 
      return { isOk: false, message: "Server error" };
   }
}



export async function updateApi(  dynamicGet: DynamicGet): Promise<ApiResponse> 
{
   try
   {
      const body = { DynamicGet: dynamicGet };
      return await sendPut('/dynamic/updateApi',  body);
   }
   catch { 
      return { isOk: false, message: "Server error" };
   }
}



export async function deleteApi(  dynamicGet: DynamicGet): Promise<ApiResponse> 
{
   try
   {
      const body = { DynamicGet: dynamicGet };
      return await sendDelete('/dynamic/deleteApi',  body);
   }
   catch { 
      return { isOk: false, message: "Server error" };
   }
}


//#endregion Methods
