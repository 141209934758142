//#region Imports

import axios, { AxiosError } from 'axios';
import { sendPost, sendGet, sendDelete, sendPatch, sendPut } from '../../../../Helpers'
import { ApiResponse } from '../../../../../types/api-response';
import { DynamicGet } from '../../../../../utils/DynamicGet'; 

//#endregion Imports

//#region Methods


export async function log(  message: string, 
  gravity: number, 
  logNamespace: string, 
  clientTimestamp: string): Promise<ApiResponse> 
{
   try
   {
      const body = { Message: message, Gravity: gravity, LogNamespace: logNamespace, ClientTimestamp: clientTimestamp };
      return await sendPost('/debugmessages/log',  body);
   }
   catch { 
      return { isOk: false, message: "Server error" };
   }
}


//#endregion Methods
