//#region Imports

import axios, { AxiosError } from 'axios';
import { sendPost, sendGet, sendDelete, sendPatch, sendPut } from '../../../Helpers'
import { ApiResponse } from '../../../../types/api-response';
import { DynamicGet } from '../../../../utils/DynamicGet'; 

//#endregion Imports

//#region Methods


export async function register(  firstName: string, 
  lastName: string, 
  email: string, 
  userName: string, 
  password: string): Promise<ApiResponse> 
{
   try
   {
      const body = { FirstName: firstName, LastName: lastName, Email: email, UserName: userName, Password: password };
      return await sendPost('/account/register',  body);
   }
   catch { 
      return { isOk: false, message: "Server error" };
   }
}



export async function confirmation(  userId: string, 
  code: string): Promise<ApiResponse> 
{
   try
   {
      const body = { UserId: userId, Code: code };
      return await sendPost('/account/confirmation',  body);
   }
   catch { 
      return { isOk: false, message: "Server error" };
   }
}



export async function forgotPassword(  email: string): Promise<ApiResponse> 
{
   try
   {
      const body = { Email: email };
      return await sendPost('/account/forgotPassword',  body);
   }
   catch { 
      return { isOk: false, message: "Server error" };
   }
}



export async function changePassword(  userId: string, 
  code: string, 
  newPassword: string): Promise<ApiResponse> 
{
   try
   {
      const body = { UserId: userId, Code: code, NewPassword: newPassword };
      return await sendPost('/account/changePassword',  body);
   }
   catch { 
      return { isOk: false, message: "Server error" };
   }
}



export async function generate2fa(  userId: string): Promise<ApiResponse> 
{
   try
   {
      const body = { UserId: userId };
      return await sendPost('/account/generate2fa',  body);
   }
   catch { 
      return { isOk: false, message: "Server error" };
   }
}



export async function validate2fa(  userId: string, 
  token: string): Promise<ApiResponse> 
{
   try
   {
      const body = { UserId: userId, Token: token };
      return await sendPost('/account/validate2fa',  body);
   }
   catch { 
      return { isOk: false, message: "Server error" };
   }
}



export async function login(  userNameOrEmail: string, 
  password: string, 
  rememberMe: boolean): Promise<ApiResponse> 
{
   try
   {
      const body = { UserNameOrEmail: userNameOrEmail, Password: password, RememberMe: rememberMe };
      return await sendPost('/account/login',  body);
   }
   catch { 
      return { isOk: false, message: "Server error" };
   }
}



export async function logout(): Promise<ApiResponse> 
{
   try
   {
      return await sendPost('/account/logout', { });
   }
   catch { 
      return { isOk: false, message: "Server error" };
   }
}

//#endregion Methods
