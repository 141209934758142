//#region Imports

import axios, { AxiosResponse, AxiosError } from 'axios';
import Cookies from 'universal-cookie';
import URI from 'urijs';
import { ApiResponse, ErrorResponse } from '../types/api-response';

//#endregion Imports

//#region Methods

const cookies = new Cookies();

function logRequest(url: string, method: string, body: any) {
  if (!url.includes("monitoring") && !url.includes("debugmessages"))
    console.log(`[api/${method.toLowerCase()} ${url}] Sent: ${JSON.stringify(body, null, 4)}`);
}

function logResponse(url: string, method: string, response: any) {
  if (!url.includes("monitoring") && !url.includes("debugmessages"))
    console.log(`[api/${method.toLowerCase()} ${url}] Receive: ${JSON.stringify(response, null, 4)}`);
}

function handleAxiosError(error: AxiosError): ApiResponse {
  let message = 'Request failed with a response error';

  if (error.response) {
    const data: ErrorResponse = error.response.data as ErrorResponse;
    console.error(`[api/error] Response error: `, data.Message);
    message = data.Message || message;
  } else if (error.request) {
    console.error(`[api/error] No response: `, error.request);
  } else {
    console.error(`[api/error] Error message: `, error.message);
  }

  return {
    isOk: false,
    message: message,
    data: error.response?.data
  };
}

async function sendRequest<T>(
  method: 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE',
  url: string,
  params: Record<string, any> = {},
  data?: Record<string, any>
): Promise<ApiResponse> {
  try {
    let fullUrl = `${process.env.REACT_APP_API_BASE_URL}${url}`;
    if (['GET', 'DELETE'].includes(method)) {
      fullUrl = URI(fullUrl).query(params).toString();
    }

    logRequest(fullUrl, method, data);
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    };
    const response: AxiosResponse<ApiResponse> = await axios({
      method: method,
      url: fullUrl,
      data: ['POST', 'PATCH', 'PUT'].includes(method) ? data : undefined,
      headers: headers,
      withCredentials: true
    });

    logResponse(fullUrl, method, response.data);

    return { isOk: response.data.isOk, message: response.data.message, data: response.data.data };

  } catch (error) {
    console.error(`[api/${method.toLowerCase()} ${url}] Unexpected error: `, error);
    
    if (axios.isAxiosError(error)) {
      return handleAxiosError(error);
    } else {
      return { isOk: false, message: 'An unexpected error occurred' };
    }
  }
}
export async function sendGet<T>(url: string, params?: Record<string, any>): Promise<ApiResponse> {
  return sendRequest<T>('GET', url, params);
}

export async function sendPost<T>(url: string, data: Record<string, any>, params: Record<string, any> = {}): Promise<ApiResponse> {
  return sendRequest<T>('POST', url, params, data);
}

export async function sendPatch<T>(url: string, data: Record<string, any>, params: Record<string, any> = {}): Promise<ApiResponse> {
  return sendRequest<T>('PATCH', url, params, data);
}

export async function sendPut<T>(url: string, data: Record<string, any>, params: Record<string, any> = {}): Promise<ApiResponse> {
  return sendRequest<T>('PUT', url, params, data);
}

export async function sendDelete<T>(url: string, params?: Record<string, any>): Promise<ApiResponse> {
  return sendRequest<T>('DELETE', url, params);
}

//#endregion Methods